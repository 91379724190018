/**
 * Configuration for RTE
 * @returns
 */
const tinyMceEditorSettings = () => {
  return {
    API_KEY: process.env.VUE_APP_RTE_EDITOR || "7kz8hfyhb58qawo883txvjz7pcii7mmdomc5gi9nw1uvgthd",
    config: {
      menubar: false,
      statusbar: true,
      plugins: [
        "autolink directionality",
        "link media table hr nonbreaking insertdatetime advlist code",
        "textpattern noneditable quickbars",
        "visualblocks visualchars fullscreen image lists linkchecker",
      ],
      toolbar:
        "undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | forecolor backcolor removeformat | insertfile image media link",
      height: 400,
      // images_upload_url: `${process.env.VUE_APP_API_URL}/rte/upload`,
      images_upload_handler: uploadHandler,
    },
  };
};

/**
 * upload Handler with bearer token
 * @param {*} blobInfo
 * @param {*} success
 * @param {*} failure
 * @param {*} progress
 */
const uploadHandler = (blobInfo, success, failure, progress) => {
  let xhr, formData;
  const token = localStorage.getItem("adminToken");

  xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.open("POST", `${process.env.VUE_APP_API_URL}/rte/upload`);
  xhr.setRequestHeader("Authorization", `Bearer ${token}`);

  xhr.upload.onprogress = function(e) {
    progress((e.loaded / e.total) * 100);
  };

  xhr.onload = function() {
    let json;
    if (xhr.status === 403) {
      failure(
        "Error occurred while uploading image. Error code is " + xhr.status,
        { remove: true }
      );
      return;
    }

    if (xhr.status === 401) {
      failure(
        "Please login again to upload the image. Error code is " + xhr.status,
        { remove: true }
      );
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      failure(
        "Error occurred while uploading image. Error code is " + xhr.status
      );
      return;
    }

    json = JSON.parse(xhr.responseText);
    if (!json || typeof json.location != "string") {
      failure("Invalid JSON: " + xhr.responseText);
      return;
    }
    success(json.location);
  };

  xhr.onerror = function() {
    failure(
      "Image upload failed due to a XHR Transport error. Code: " + xhr.status
    );
  };

  formData = new FormData();
  formData.append("file", blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
};

export default tinyMceEditorSettings;
