<template>
  <div class="card text-left">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col-8">
          <h3 class="mb-0">Edit Event</h3>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form novalidate @submit.prevent="submitHandler()">
        <div class="pl-lg-4">
          <div class="row">
            <div
              v-for="(field, i) in schema.info"
              :key="i"
              :class="field.colClass"
            >
              <div class="form-group bg-silver p-2 rounded">
                <template v-if="field.type === 'checkbox'">
                  <span class="form-control-label">{{ field.label }}</span>
                  <br />
                  <label class="custom-toggle">
                    <input
                      v-model="form[field.model]"
                      :required="field.required"
                      type="checkbox"
                    />
                    <span
                      class="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    ></span>
                  </label>
                </template>

                <template v-else-if="field.type === 'select'">
                  <label :for="field.label" class="form-control-label">{{
                    field.label
                  }}</label>
                  <select
                    v-model="form[field.model]"
                    :class="{ 'is-invalid': errors[field.model] }"
                    :multiple="field.multiple"
                    :required="field.required"
                    :size="field.size"
                    class="form-control"
                  >
                    <option
                      v-for="(option, i) in getDropdowns[field.options]"
                      :key="i"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </template>

                <template v-else-if="field.type === 'image'">
                  <label class="form-control-label"
                    >{{ field.label }}
                    <small
                      >(<a :href="form[field.model]" target="_blank">view</a
                      >)</small
                    ></label
                  >
                  <div class="custom-file">
                    <input
                      :id="field.label"
                      :required="field.required"
                      class="custom-file-input"
                      type="file"
                      @change="handleFileUpload($event, field.model)"
                    />
                    <label :for="field.label" class="custom-file-label">{{
                      field.label
                    }}</label>
                  </div>
                </template>

                <template v-else-if="field.type === 'ckeditor'">
                  <label class="form-control-label required">{{
                    field.label
                  }}</label>
                  <editor
                    class="form-control"
                    :id="`ckeditor-${field.model}`"
                    v-model="form[field.model]"
                    :api-key="ckeditor.API_KEY"
                    :init="ckeditor.config"
                  >
                  </editor>
                </template>

                <template v-else-if="field.type === 'tokenize'">
                  <label :for="field.label" class="form-control-label">{{
                    field.label
                  }}</label>
                  <multiselect
                    v-model="form[field.model]"
                    :clear-on-select="false"
                    :close-on-select="false"
                    :multiple="true"
                    :options="getDropdowns[field.options]"
                    :preselect-first="true"
                    :preserve-search="true"
                    label="label"
                    placeholder="Select Countries"
                    track-by="label"
                    @select="handleSelect($event, form[field.model])"
                  >
                    <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                    >
                      <span
                        v-if="values.length &amp;&amp; !isOpen"
                        class="multiselect__single"
                        >{{ values.map((p) => p.label).join(",") }}</span
                      >
                    </template>
                  </multiselect>
                </template>

                <template v-else-if="field.type === 'time'">
                  <label :for="field.label" class="form-control-label">{{
                    field.label
                  }}</label>
                  <input
                    :id="field.label"
                    v-model="form[field.model]"
                    :class="{ 'is-invalid': errors[field.model] }"
                    :required="field.required"
                    :type="field.type"
                    step="1"
                    class="form-control"
                  />
                </template>

                <template v-else>
                  <label :for="field.label" class="form-control-label">{{
                    field.label
                  }}</label>
                  <input
                    :id="field.label"
                    v-model="form[field.model]"
                    :class="{ 'is-invalid': errors[field.model] }"
                    :required="field.required"
                    :type="field.type"
                    class="form-control"
                  />
                </template>
              </div>
            </div>
          </div>

          <!-- <pre>{{ form.categories }}</pre> -->
          <h3>Event Categories</h3>
          <div class="bg-silver2 p-3 rounded mb-3 border">
            <TransitionGroup name="fade">
              <div
                class="row"
                v-for="(categoryStartTime, catTimeIndex) in form.categories"
                :key="catTimeIndex"
              >
                <div class="form-group col-lg-12" style="margin-bottom: 5px;">
                  <label class="mt-4 font-weight-bold">{{
                    categoryStartTime.Label
                  }}</label>
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label">Start Date</label>
                  <input
                    v-model.lazy="form.categories[catTimeIndex].date"
                    type="date"
                    class="form-control"
                    was-validated
                    :min="todayDate()"
                    ref="catStartDate"
                  />
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label">Start Time</label>
                  <input
                    v-model.lazy="form.categories[catTimeIndex].time"
                    type="time"
                    class="form-control"
                    step="1"
                    was-validated
                    ref="catStartTime"
                  />
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label">Start Spline Index</label>
                  <input
                    v-model.lazy="
                      form.categories[catTimeIndex].StartSplineIndex
                    "
                    type="text"
                    class="form-control"
                    was-validated
                  />
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label">Spline Path</label>
                  <input
                    v-model.lazy="form.categories[catTimeIndex].SplinePath"
                    type="text"
                    class="form-control"
                    was-validated
                  />
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label"
                    >Start Spline Distance</label
                  >
                  <input
                    v-model.lazy="
                      form.categories[catTimeIndex].StartSplineDistance
                    "
                    type="text"
                    class="form-control"
                    was-validated
                  />
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label">Total Distance</label>
                  <input
                    v-model.lazy="form.categories[catTimeIndex].TotalDistance"
                    type="text"
                    class="form-control"
                    was-validated
                  />
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label">Elevation</label>
                  <input
                    v-model.lazy="form.categories[catTimeIndex].Elevation"
                    type="text"
                    class="form-control"
                    was-validated
                  />
                </div>
                <div class="form-group col-lg-2">
                  <label class="form-control-label">Direction</label>
                  <select
                    class="form-control"
                    v-model.number="
                      form.categories[catTimeIndex].StartCheckpointDirection
                    "
                  >
                    <option
                      v-for="(option, i) in direction"
                      :key="i"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>
              </div>
            </TransitionGroup>
          </div>

          <h3>Event Winners</h3>
          <div
            v-for="(winner, w) in form.winners"
            :key="w"
            class="bg-silver2 p-3 rounded mb-3 border"
          >
            <button
              class="btn btn-sm btn-danger float-right"
              type="button"
              @click="deleteWinner(w)"
            >
              delete
            </button>
            <div class="clearfix"></div>
            <div class="row">
              <div
                v-for="(field, j) in schema.winners"
                :key="j"
                :class="field.colClass"
              >
                <div v-if="field.type === 'select'">
                  <label :for="field.label" class="form-control-label">{{
                    field.label
                  }}</label>
                  <select
                    v-model="form.winners[w][field.model]"
                    :class="{ 'is-invalid': errors[field.model] }"
                    :multiple="field.multiple"
                    :required="field.required"
                    class="form-control"
                  >
                    <option
                      v-for="(option, i) in getDropdowns[field.options]"
                      :key="i"
                      :value="option.value"
                    >
                      {{ option.label }}
                    </option>
                  </select>
                </div>

                <div v-else class="form-group">
                  <label :for="field.label" class="form-control-label">{{
                    field.label
                  }}</label>
                  <input
                    v-model.number="form.winners[w][field.model]"
                    class="form-control"
                    type="text"
                  />
                </div>
              </div>
              <br />
              <hr />
            </div>
          </div>

          <div class="text-right">
            <button
              class="btn btn-sm btn-outline-primary"
              type="button"
              @click="addWinnerSection()"
            >
              Add New Winners Section
            </button>
          </div>
        </div>

        <div class="pl-lg-4">
          <button class="btn btn-primary" type="submit">Update</button>
          <div v-if="invalidDateTimeMessage" class="text-danger">
            {{ invalidDateTimeMessage }}
          </div>
          <div v-if="invalidTeamSizeMessage" class="text-danger">
            {{ invalidTeamSizeMessage }}
          </div>
        </div>

        <div
          v-if="getServerErrors"
          class="alert alert-danger rounded mt-2"
          v-html="getServerErrors"
        ></div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import Schema from "@/store/in-memory-db/forms/event.json";
import Countries from "@/json/countries.json";
import Genders from "@/json/genders.json";
import eventTypes from "@/json/eventTypes.json";
import Editor from "@tinymce/tinymce-vue";
import CkConfig from "@/json/ckEditor.js";
import "vue-multiselect/dist/vue-multiselect.min.css";
// import * as dayjs from "dayjs";

export default {
  name: "AdminPagesEdit",
  inject: ["dropdowns"],
  components: {
    Multiselect,
    editor: Editor,
  },

  data() {
    return {
      schema: Schema,
      editors: {},
      direction: [
        { label: "Forward", value: "1" },
        { label: "Backward", value: "-1" },
      ],
      images: [],
      invalidDateTimeMessage: null,
      invalidTeamSizeMessage: null,
      form: {
        id: null,
        // serverEventId: null,
        winners: [],
        dob_required: false,
      },
      ckeditor: CkConfig(),
    };
  },

  computed: {
    ...mapGetters("event", ["event"]),
    ...mapGetters(["errors"]),
    ...mapGetters("routes", ["getRoutes"]),

    getServerErrors() {
      if (!this.errors) return false;
      return Object.values(this.errors).join("<br>");
    },

    categories() {
      if (!this.dropdowns.categories) return;
      const categories = this.dropdowns.categories.map(cat => {
        return {
          "value": 'category-'+cat.CategoryId,
          "label": cat.Label
        } 
      });
      
      categories.unshift({ value: "", label: "All Category" });
      categories.push({ value: "open", label: "Open" });
      return categories;
    },

    getDropdowns() {
      return {
        eventTypeDropdown: eventTypes,
        eventCategoryDropdown: this.categories,
        genderDropdown: Genders,
        routeDropdown: {},
        countriesDropdown: Countries,
      }
    },
  },

  async mounted() {
    this.$store.dispatch("event/event", this.$route.params.id).then((event) => {
      const eventData = event.data;
      this.form.winners = JSON.parse(JSON.stringify(eventData.winners));

      if (eventData.isNew) {
        this.form.countries = this.getDropdowns.countriesDropdown;
      }

      // set default dob_required value
      this.form.dob_required = eventData?.dob_required || false;

      if (!eventData.isNew) {
        this.form.countries = this.getDropdowns.countriesDropdown.filter((x) =>
          this.form.countries.includes(x.value)
        );
      }
    });

    if (this.getRoutes.length === 0) {
      await this.$store.dispatch("routes/loadRoutes");
    }

    this.getDropdowns.routeDropdown = this.getRoutes;
  },

  watch: {
    event: function(val) {
      this.form = Object.assign({}, val);
    },
  },

  methods: {
    handleSelect(event, model) {
      if (model && event.value === "SA") {
        // select all
        for (let item of this.getDropdowns.countriesDropdown) {
          if (
            item.value !== "SA" &&
            !model.filter((x) => x.value === item.value).length
          ) {
            if (item.value !== "SA") {
              model.push(item);
            }
          }
        }
      }
    },

    addWinnerSection: function() {
      const nw = {};
      for (let w in this.schema.winners) {
        w = parseInt(w) + 1;
        nw[`winner_${w}`] = "";
      }

      this.form.winners.push(nw);
    },

    deleteWinner(index) {
      this.form.winners.splice(index, 1);
    },

    handleFileUpload(event, name) {
      this.form[name] = event.target.files[0];
    },

    async submitHandler() {
      this.invalidDateTimeMessage = null;
      this.invalidTeamSizeMessage = null;
      // const eventDateTime = `${this.form.date} ${this.form.time}`;
      // const eventClosingDateTime = `${this.form.closing_date} ${this.form.closing_time}`;

      // if (dayjs(eventDateTime).diff(dayjs(eventClosingDateTime)) < 0) {
      //   this.invalidDateTimeMessage =
      //     "Registration closing time must be before event closing time";
      //   return;
      // }

      if (this.form.teamCalculationSize > this.form.teamSize) {
        this.invalidTeamSizeMessage =
          "Team calculation size should be less than or equal to Team size limit.";
        return;
      }

      const formData = new FormData();
      Object.keys(this.form).map((key) => {
        if (key === "winners") {
          Object.keys(this.form[key]).map((sk) => {
            Object.keys(this.form[key][sk]).map((ssk) => {
              formData.append(
                "winners[" + sk + "][" + ssk + "]",
                this.form[key][sk][ssk]
              );
            });
          });
        } else if (key === "categories") {
          Object.keys(this.form[key]).map((cat_key) => {
            Object.keys(this.form[key][cat_key]).map((cat) => {
              formData.append(
                "categories[" + cat_key + "][" + cat + "]",
                this.form[key][cat_key][cat]
              );
            });
          });
        } else if (key === "countries") {
          formData.append(
            key,
            this.form[key].map((x) => x.value)
          );
        } else {
          if (key === "datetime" || key === "closing_datetime") {
            formData.append(key, this.form[key].toString());
          } else {
            formData.append(key, this.form[key]);
          }
        }
      });

      await this.$store.dispatch("event/update", formData).then((res) => {
        if (res?.status) {
          this.$router.push({ name: "AdminEventsList" });
        }
      });
    },

    todayDate() {
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      return `${yyyy}-${mm}-${dd}`;
    },
  },
};
</script>

<style scoped>
.error-12 {
  font-size: 12px;
}

.bg-silver {
  background-color: #e7e7e7;
}

.bg-silver2 {
  background-color: #f7f7f7;
}

.form-control-label.required::after {
  content: " (required *)";
  color: red;
  font-weight: normal;
}
</style>
